export default [
  {
    name: "Current Apps",
    sites: [
      {
        name: "BBA Applicant Assessments",
        note: "SOB BBA Program future student assessment tool",
        sites: [
          {
            name: "Public",
            url: "https://bbaapplicant.bcitsitecentre.ca/",
          },
          {
            name: "Portal",
            url: "https://bbaportal.bcitsitecentre.ca/",
          },
        ],
      },

      {
        name: "Broadcast Centre - Bookings1 App",
        note: "Lab Bookings App and Equipment Fault Report",
        sites: [
          {
            name: "     ",
            url: "",
          },
          {
            name: "Portal",
            url: "https://equipmentfault.bcitsitecentre.ca",
          },
        ],
      },

      {
        name: "Campfire Beta (off-line)",
        note: "with LTC Digital Assessment",
        sites: [
          {
            name: "Public",
            url: "https://www.campfirebc.ca/",
          },
          {
            name: "Portal",
            url: "https://campfireportal.bcitsitecentre.ca/",
          },
        ],
      },

      {
        name: "Career Build (off-line)",
        note: "Future student LM source - rebuilt April 2021 SOB",
        sites: [
          {
            name: "     ",
            url: "",
          },
          {
            name: "Public",
            url: "https://careerbuild.bcit.ca/",
          },
        ],
      },

      {
        name: "Digital Badging and Microcredential Mgmt",
        note: "Assigning portal for BCIT Digital Badges, Microcredentials (Agile)",
        sites: [
          {
            name: "Public (ref)",
            url: "https://www.bcit.ca/explore/microcredentials/",
          },
          {
            name: "Portal",
            url: "https://microcredentialportal.bcit.ca",
          },
        ],
      },

      {
        name: "EMBER (off-line)",
        note: "General Canadian Military support resource listings (part of N-APPL)",
        sites: [
          {
            name: "Public ",
            url: "https://ember-pathway.ca/",
          },

          {
            name: "Portal",
            url: "https://portal.ember-pathway.ca/",
          },
        ],
      },

      {
        name: "I-APPL",
        note: "Yorkville Univ. offshoot of N-APPL to assess specified NOCs against  Academic outcomes",
        sites: [
          {
            name: "     ",
            url: "",
          },

          {
            name: "Portal",
            url: "https://portal.industry-appl.ca/",
          },
        ],
      },

      {
        name: "Industry PDS (off-line)",
        note: "V1 of Site showcase website, succeeded by RELM ",
        sites: [
          {
            name: "Public",
            url: "https://www.industrypds.ca/",
          },

          {
            name: "Portal",
            url: "http://industrypds.wpengine.com",
          },
        ],
      },

      {
        name: "Legion BC Assessment APPL (off-line)",
        note: "BC Miiltary to BCIT program assessment application (BC/Yukon Legion)",
        sites: [
          {
            name: "Public",
            url: "https://legion.bcitsitecentre.ca/",
          },
          {
            name: "Portal",
            url: "https://legionportal.bcitsitecentre.ca/",
          },
        ],
      },

      {
        name: "Marks Review Application",
        note: "SOB Internal Marks Review application (built-maintained by SOBM Digital Arts program)",
        sites: [
          {
            name: "     ",
            url: "",
          },

          {
            name: "Portal",
            url: "https://marksreview.bcit.ca/",
          },
        ],
      },

      {
        name: "MEP LegionBC Jobs Board",
        job: "BC Military focused Jobs Board (BC/Yukon Legion)",
        sites: [
          {
            name: "Public",
            url: "https://mepjobs.ca/",
          },
          {
            name: "Portal",
            url: "https://portal.mepjobs.ca/",
          },
        ],
      },

      {
        name: "N-APPL (off-line)",
        note: "Original Canadian Military training against Academic Outcomes portal (HRDC, BCIT)",
        sites: [
          {
            name: "Public",
            url: "https://www.military-appl.ca/",
          },
          {
            name: "Portal",
            url: "https://portal.military-appl.ca/",
          },
        ],
      },

      {
        name: "Potree Building LiDAR 3-D Visuals",
        note: "BCIT RPAS Limited Building Views",
        sites: [
          {
            name: "BCIT Public Page",
            url: "https://www.bcit.ca/learning-teaching-centre/remotely-piloted-aircraft-systems/research-projects/lidar-3d-models-of-the-burnaby-campus/",
          },
          {
            name: "Visuals-Example-HealthSciences",
            url: "https://potree.bcitsitecentre.ca/health/health.html",
          },
        ],
      },




      {
        name: "PTS Course Waitlist (off-line)",
        note: "SOB PTS Waitlist application for students",
        sites: [
          {
            name: "Public",
            url: "https://sobptswaitlist.bcitsitecentre.ca",
          },
          {
            name: "Portal",
            url: "https://sobptsportal.bcitsitecentre.ca",
          },
        ],
      },

      {
        name: "RELM (Resources for Education and the Labour Market) (off-line)",
        note: "Beta, showcase web site for Site and other BCIT support depts  (VP Academic)",
        sites: [ 
          {
            name: "Public",
            url: "https://relm-85f603.netlify.app/",
          },

          {
            name: "Portal",
            url: "https://relm.wpengine.com/wp-admin/",
          },
        ],
      },

      {
        name: "RIS-Admissions (off-line)",
        note: "Demo BCIT to other entities pre-reqs crosswalk (taken down - RO)",
        sites: [
          {
            name: "     ",
            url: "",
          },

          {
            name: "Portal",
            url: "https://ris.industry-appl.ca/",
          },
        ],
      },

      {
        name: "SE10 Digital Building Pass (off-line)",
        note: "Repurposed beta mobile digital daily building access app (taken down)",
        sites: [
          {
            name: "     ",
            url: "",
          },

          {
            name: "Public",
            url: "https://bcitse10.ca",
          },
        ],
      },

      {
        name: "SITE Centre Assessment (off-line)",
        note: "Main Site future student assessment app - various streams",
        sites: [
          {
            name: "Public",
            url: "https://register.bcitsitecentre.ca/",
          },

          {
            name: "Portal",
            url: "https://siteportal.bcitsitecentre.ca/",
          },
        ],
      },

      {
        name: "Timetabling Current (moved URLs)",
        note: "Enterprise timetabling system (VP Academic)",
        sites: [
          {
            name: "Public",
            url: "https://timetables.bcitsitecentre.ca/",
          },
          {
            name: "Portal",
            url: "https://timetablesportal.bcitsitecentre.ca",
          },
        ],
      },
  
  
  {
        name: "Timetabling (New)",
        note: "Enterprise timetabling system with Facilities",
        sites: [
          {
            name: "Public",
            url: "https://timetables.bcitsitecentre.ca/",
          },
          {
            name: "Portal",
            url: "https://timetablingsystem.bcitsitecentre.ca/",
          },
        ],
      },
    ],
  },
  
  
  
  {
    name: "Corporate Training Planner (CT4U)",
    sites: [
      {
        name: "Brazil (off-line)",
        note: "General note, all application segments in beta (VP Academic)",
        sites: [
          {
            name: "Client",
            url: "https://ct4u-br.netlify.app",
          },
          {
            name: "Wordpress",
            url: "https://ct4u.wpengine.com/",
          },
          {
            name: "Regional",
            url: "https://ct4u-br-admin.netlify.app",
          },
        ],
      },

      {
        name: "Canada (off-line)",
        note: "BCIT",
        sites: [
          {
            name: "Client",
            url: "https://ct4u-ca.netlify.app",
          },
          {
            name: "Wordpress",
            url: "https://ct4u.wpengine.com/",
          },
          {
            name: "Regional",
            url: "https://ct4u-ca-admin.netlify.app",
          },
        ],
      },

      {
        name: "Colombia  (off-line)",
        note: "ITSA",
        sites: [
          {
            name: "Client",
            url: "https://ct4u-co-itsa.netlify.app/",
          },
          {
            name: "Wordpress",
            url: "https://ct4u.wpengine.com/",
          },
          {
            name: "Regional",
            url: "https://ct4u-co-itsa-admin.netlify.app",
          },
        ],
      },

      {
        name: "Canada  (off-line)",
        note: "Quebec-French language version",
        sites: [
          {
            name: "Client",
            url: "https://ct4u-fr.netlify.app/",
          },
          {
            name: "Wordpress",
            url: "https://ct4u.wpengine.com/",
          },
          {
            name: "Regional",
            url: "https://ct4u-fr-admin.netlify.app/",
          },
        ],
      },

      {
        name: "South Africa  (off-line)",
        note: "",
        sites: [
          {
            name: "Client",
            url: "https://ct4u-sa.netlify.app/",
          },
          {
            name: "Wordpress",
            url: "https://ct4u.wpengine.com/",
          },
          {
            name: "Regional",
            url: "https://ct4u-sa-admin.netlify.app",
          },
        ],
      },
    ],
  },

  {
    name: "R&D And Legacy Apps",
    sites: [
      {
        name: "360",
        note: "off-line: student-to-student peer reviews (SOB BBA)",
        sites: [
          {
            name: "",
            url: "",
          },
          {
            name: "",
            url: "",
          },
        ],
      },

      {
        name: "Campfire Wirefame Concepts",
        note: "Now in beta production ",
        sites: [
          {
            name: "",
            url: "",
          },
          {
            name: "Wireframe",
            url: "https://www.figma.com/file/1Bpg0Aum5jvH7kRIW6aenM/Phase-II-Mobile-Wireframe?node-id=0%3A1",
          },
        ],
      },

      {
        name: "Forestry BC Branding (off-line)",
        note: "Student Beta May 2021",
        sites: [
          {
            name: "",
            url: "",
          },
          {
            name: "Wireframe",
            url: "https://www.figma.com/file/V3HponJ53n74Kfus4noty3/Forestry-BC?node-id=44%3A18",
          },
        ],
      },

      {
        name: "PERSIST (off-line)",
        note: "Project Mgmt Overview - SITE Methodology",
        sites: [
          {
            name: "",
            url: "",
          },
          {
            name: "BETA (pw-PERSIST)",
            note: "Project Mgmt showcase app (beta)",
            url: "https://persist.bcitsitecentre.ca/",
          },
        ],
      },

      {
        name: "PTS English Verification",
        note: "Student mobile survey and staff portal (taken down)",
        sites: [
          {
            name: "",
            url: "",
          },
        ],
      },

      {
        name: "BC Student Outcomes Dashboard",
        note: "AVED retired BCIT Site design Sep 2020",
        sites: [ 
          {
            name: "Public",
            url: "http://www2.bcstats.gov.bc.ca/Dashboard/",
          },
        ],
      },

      {
        name: "BC Student Scavenger Hunt",
        note: "SOB student orientation app (taken down)",
        sites: [
          {
            name: "Wireframes",
            url: "https://www.figma.com/file/wgXApxbC0Pw9L9EKT8q76s/Scavenger-Hunt-App?node-id=0%3A1",
          },
        ],
      },
    ],
  },
];
