import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Tabs,
  Tab,
  Divider,
  AppBar,
  Box,
} from "@material-ui/core";

import BCITLogo from "./images/bcit.png";

import data from "./data";
import Banner from "../src/images/Hero-image.png";

const key = `E2C9FBC4A7842497331E4D66D5356`;

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Be Vietnam Pro', sans-serif;",
    h1: {
      fontSize: "4rem",
      letterSpacing: "-0.01em",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    h2: {
      fontSize: "3.5rem",
    },
    h3: {
      fontSize: "3.5rem",
    },
    h4: {
      fontSize: "1rem",
      letterSpacing: "-0.01em;",
      fontWeight: "500",
    },
  },
  palette: {
    primary: {
      main: "#0F56C5",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FFD800",
    },
    text: {
      primary: "#0F56C5",
    },
  },
  overrides: {
    MuiTabs: {
      indicator: {
        height: 8,
        bottom: 20,
        zIndex: -1,
      },
      flexContainer: {
        justifyContent: "space-between",
      },
    },
    MuiTab: {
      root: {
        maxWidth: "none",
        minWidth: "auto",
        paddingLeft: 0,
        textTransform: "none",
        letterSpacing: "1px",
        fontSize: "1.8rem",
        fontWeight: "bold",
        "&.MuiTab-root": {
          "&.hover": {
            color: "#105CD3",
          },
        },

        "&.Mui-selected": {
          color: "#105CD3",
          fontWeight: "700",
        },
      },
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    MuiButton: {
      root: {
        letterSpacing: "-0.01em",
      },
      contained: {
        boxShadow: "none",
        borderRadius: 0,
      },
    },
  },
});

function App() {
  const [authed, setAuthed] = useState(false);
  const [password, setPassword] = useState("");
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (window.localStorage.getItem(key)) {
      setAuthed(true);
    }
  }, []);

  const handleSubmit = () => {
    if (password === "bunker") {
      setAuthed(true);
      setPassword("");
      window.localStorage.setItem(key, true);
    }
  };

  const handleLogout = () => {
    setAuthed(false);
    window.localStorage.removeItem(key, true);
  };

  return (
    <MuiThemeProvider theme={theme}>
      {authed ? (
        <>
          <HeroContainer>
            <Hero>
              <Typography variant="h1" children="BCIT SITE Applications" />
              <Box class="image-container">
                <img src={Banner} alt='Banner'/>
              </Box>
            </Hero>
          </HeroContainer>
          <TabsContainer position="static" color="default">
            <Edges>
              <Tabs
                centered
                value={tab}
                onChange={(e, newValue) => setTab(newValue)}
                scrollButtons="auto"
                variant="scrollable"
                indicatorColor="primary"
              >
                {data.map((o) => {
                  return <StyledTab key={o.name} label={o.name} />;
                })}
              </Tabs>
            </Edges>
          </TabsContainer>

          <Edges>
            {data.map((o, i) => {
              return (
                <TabContent key={o.name} active={i === tab}>
                  {o.sites.map((p) => {
                    return (
                      <TabContentItem
                        key={p.name}
                        container
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={5}>
                          <Typography
                            variant="h4"
                            children={p.name}
                            gutterBottom
                          />
                          <p className="note">{p.note}</p>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <Domains container>
                            {p.sites.map((q) => {
                              return (
                                <>
                                  {q.name && q.url && (
                                    <Domain key={q.name}>
                                      <a
                                        href={q.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {q.name}
                                      </a>
                                    </Domain>
                                  )}
                                </>
                              );
                            })}
                          </Domains>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </TabContentItem>
                    );
                  })}
                </TabContent>
              );
            })}
          </Edges>
          <Footer>
            <FooterLogo>
              <img src={BCITLogo} alt="Logo" />
            </FooterLogo>
            <FooterText>Powered by BCIT</FooterText>
            {authed && (
              <FooterLogout>
                <Typography children={"Logout"} onClick={handleLogout} />
              </FooterLogout>
            )}

            {/* <FooterFaculty>
                  <img src={CowboyHat} alt="Logo" />
                  <FooterText>
                    <a href="http://faculty.bcitbusiness.ca/kevinw/">
                      Kevin&apos;s Faculty Page
                    </a>
                  </FooterText>
                </FooterFaculty> */}
          </Footer>
        </>
      ) : (
        <PasswordContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Enter Password"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                variant="filled"
                fullWidth
                onKeyPress={(event) => event.key === "Enter" && handleSubmit()}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                children={"Submit"}
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
                fullWidth
              />
            </Grid>
          </Grid>
        </PasswordContainer>
      )}
    </MuiThemeProvider>
  );
}

const PasswordContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
`;

const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38vh;
  min-height: 300px;
  max-height: 350px;
  background-color: #093477;

  h1 {
    color: #fff;
    max-width: 400px;
  }
`;

const Hero = styled.div`
  width: 944px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .image-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 200px;

    img {
      object-fit: cover;
      z-index: 0;
      object-position: bottom;
    }
  }
`;


const Edges = styled.div`
  position: relative;
  max-width: 944px;
  width: 100%;
  margin: 0 auto;
`;

const TabsContainer = styled(AppBar)`
  && {
    box-shadow: none;
    margin: 0px 0px 24px 0px;
    padding-top: 24px;
    background-color: #fff;

    > div {
      @media (max-width: 640px) {
        width: 100%;
      }
    }

    .MuiTabs-scrollButtonsDesktop {
      display: flex;
    }
  }
`;

const StyledTab = styled(Tab)`
  && {
    font-size: 16px;
    letter-spacing: -0.01em;
    margin-bottom: 24px;
    justify-content: center;
    color: #82828a;
    font-weight: 400;
  }
`;

const TabContent = styled.div`
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-wrap: wrap;
  padding: 4vw 0 8vw;
  margin: 0 auto;
`;

const TabContentItem = styled(Grid)`
  && {
    margin-bottom: 16px;
  }

  p.note {
    font-size: 14px;
    color: #82828a;
    margin: 0px 0px 16px 0px;
  }
`;

const Domains = styled(Grid)`
  && {
    margin-bottom: 25px;

    @media (min-width: 340px) {
      margin-bottom: 10px;
      justify-content: flex-end;
    }
  }
`;

const Domain = styled(Typography)`
  && {
    display: inline-block;
    margin-right: 4vw;

    @media (min-width: 768px) {
      margin-right: 0;
    }

    a {
      position: relative;
      color: #105cd3;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
      padding: 8px;

      &:hover {
        background-color: #f7f8f9;
        transition: ease all 0.2s;
      }
    }
  }
`;

const Footer = styled.div`
  background: #f7f8f9;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterText = styled(Typography)`
  && {
    color: #000000;
    font-weight: 500px;
    font-size: 14px;

    @media (min-width: 640px) {
      padding: 0px 16px;
    }
  }
`;

const FooterLogo = styled.div`
  display: flex;
  justify-content: center;
  width: 50px;

  p {
    width: 100%;
    text-align: center;

    @media (min-width: 640px) {
      width: auto;
      text-align: left;
    }
  }

  img {
    width: 100%;
    /* @media (min-width: 640px) {
      margin: 0 10px 0 0;
    } */
  }
`;

const FooterLogout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 30px 0;
  font-size: 14px;

  @media (min-width: 640px) {
    margin: 0;
    width: auto;
    justify-content: flex-start;
  }

  p {
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
  }
`;

export default App;
